export const Colors = {
  Selago: '#ebf2fc',
  Denim: '#1976D2',
  White: '#ffffff',
  Black: '#000000',
  Gray: '#bebebe',
  CuriousBlue: '#2ca3c7',
  Sail: '#a4e2f5',
  PastelGreen: '#8bed78',
  BlizzardBlue: '#b2e5ed',
  Thunderbird: '#D13519',
};
